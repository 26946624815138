/**
 * PostFilter class.
 */

class PostFilter {
	constructor() {
		this.filterForm   = document.getElementById( 'filter-form' );
		this.postsWrapper = document.getElementById( 'posts-wrapper' );
		this.pagination   = document.querySelector( '.pagination' );

		this.init();
	}

	init() {
		// Handle filter form submission.
		this.filterForm?.addEventListener(
			'submit',
			(e) => {
            e.preventDefault();
            this.filterPosts();
			}
		);

		if (this.pagination) {
			document.addEventListener(
				'click',
				(e) => {
					if (e.target.matches( '.pagination a' )) {
						e.preventDefault();
						const page = e.target.getAttribute( 'data-page' ) || 1;
						this.filterPosts( page );
	
						// Scroll to posts-wrapper.
						window.scrollTo(
						{
							top: this.postsWrapper.offsetTop - 350,
							behavior: 'smooth'
						}
						);
					}
				}
			);
		}
	}

	async filterPosts(page = 1) {
		const selectedCategory = this.filterForm?.querySelector( '#category' ).value || currentCategory; // currentCategory set in archive.php
		const selectedTag      = this.filterForm?.querySelector( '#tag' ).value || currentTag; // currentTag set in archive.php
		const defaultCategory  = this.filterForm?.getAttribute( 'data-category-name' );

		const postData = {
			action: 'filter_posts',
			page,
			filterData: {
				category: selectedCategory,
				tag: selectedTag,
				defaultCategory
			},
			nonce: debraData.nonce
		};

		try {
			// Show loading state.
			this.postsWrapper.classList.add( 'loading' );
			this.pagination.style.display = 'none';

			const response = await fetch(
				debraData.ajax_url,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/x-www-form-urlencoded'
					},
					body: this.encodeFormData( postData )
				}
			);

			// Parse the JSON response.
			const result = await response.json();

			// Update the posts and pagination.
			this.postsWrapper.innerHTML   = result.posts;
			this.pagination.innerHTML     = result.pagination;
			this.pagination.style.display = 'block';
			this.postsWrapper.classList.remove( 'loading' );
		} catch (error) {
			this.postsWrapper.classList.remove( 'loading' );
			alert( 'Something went wrong.' );
		}
	}

	// Helper function to encode data for POST request.
	encodeFormData(data) {
		return Object.keys(data)
			.map(key => {
				if (typeof data[key] === 'object') {
					return Object.keys(data[key])
						.map(subKey => `${encodeURIComponent(key)}[${encodeURIComponent(subKey)}]=${encodeURIComponent(data[key][subKey])}`)
						.join('&');
				}
				return `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`;
			})
			.join('&');
	}
}

document.addEventListener( 'DOMContentLoaded', () => new PostFilter() );
