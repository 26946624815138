const searchForm = document.querySelector(".site-header__search-form");
const searchToggle = document.querySelector(".site-header__secondary-nav-search");
const searchToggleClose = document.querySelector(".site-header__search-form-close");

var searchInput = searchForm.querySelector(".site-header__search-form-input");
// if the SearchWP plugin is active, the search input class changes
if ( searchForm.querySelector(".swp-input--search") ) {
    var searchInput = searchForm.querySelector(".swp-input--search");
}

function toggleSearchForm() {
    if (searchForm.classList.contains("site-header__search-form--active")) {
        searchForm.classList.remove("site-header__search-form--active");
        searchToggle.classList.remove("site-header__secondary-nav-search--active");
        searchForm.setAttribute("aria-hidden", "true");
    } else {
        searchForm.classList.add("site-header__search-form--active");
        searchToggle.classList.add("site-header__secondary-nav-search--active");
        searchForm.setAttribute("aria-hidden", "false");
        searchInput.focus();
    }
}
searchToggle.addEventListener("click", toggleSearchForm);
searchToggleClose.addEventListener("click", toggleSearchForm);

// if the search form is active and focussed, close it on press of Esc.
document.addEventListener('keydown', (event) => {  
    
    if (event.key === 'Escape') {
        if ( searchForm.classList.contains("site-header__search-form--active") && document.activeElement === searchInput ) {
            searchForm.classList.remove("site-header__search-form--active");
            searchToggle.classList.remove("site-header__secondary-nav-search--active");
            searchForm.setAttribute("aria-hidden", "true");
        }
    }
});